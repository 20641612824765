import { ExclamationCircleIcon, PlusIcon } from '@heroicons/react/24/outline'

import Button from '@components/buttons/button'
import ButtonLoader from '@components/buttons/button-loader/button-loader'
import { type AvailabilitiesListProps } from '@pages/booking-requests/entry/availabilities-list/availabilities-list.interfaces'
import useGetAvailabilities from '@services/api/resources/use-get-availabilities'

const AvailabilitiesList = ({ bookingRequest, hotel, onSelect, roomNumber }: AvailabilitiesListProps) => {
  const {
    data: {
      'hydra:member': availabilities = []
    } = {},
    isLoading
  } = useGetAvailabilities({
    parameters: {
      bookingRequest,
      hotel,
      roomNumber
    }
  })

  if (isLoading) {
    return (
      <div className='mt-10 relative flex flex-col items-center w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center'>
        <ButtonLoader dark />

        <span className='mt-2 block text-sm text-gray-900'>
          Recherche de disponibilités
        </span>
      </div>
    )
  }

  if (availabilities.length === 0) {
    return (
      <div className='mt-10 relative block w-full rounded-lg border-2 border-dashed border-red-200 p-12 text-center'>
        <ExclamationCircleIcon className='mx-auto h-12 w-12 text-red-200' />

        <span className='mt-2 block text-sm text-gray-900'>
          Aucune disponibilité pour cet hôtel sur ces dates
        </span>
      </div>
    )
  }

  return (
    <>
      {availabilities.map((availability) => (
        <div className='border rounded-lg my-2' key={availability['@id']}>
          <p className='font-bold bg-slate-100 p-4'>
            {availability.room.description.fr}
          </p>

          <ul className='divide-y divide-gray-100'>
            {availability.rates.map((rate) => (
              <li
                className='flex items-center justify-between gap-x-6 px-4 py-5'
                key={rate.rateKey}
              >
                <div className='flex-1 min-w-0 w-3/5'>
                  <p className='text-sm font-semibold leading-6 text-gray-900'>
                    {rate.boardName}
                  </p>
                </div>

                <div className='w-1/5 text-right'>
                  {`${rate.net / 100} €`}
                </div>

                <div className='w-1/5 flex flex-none items-center justify-end'>
                  <Button
                    icon={PlusIcon}
                    onClick={() => {
                      onSelect(rate, availability.room.code)
                    }}
                    size='small'
                    style='secondary'
                    type='button'
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </>
  )
}

export default AvailabilitiesList
