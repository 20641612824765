import { type BadgeInterfaces } from '@components/badge'

const Badge = ({ text }: BadgeInterfaces) => {
  return (
    <div
      className='inline-flex items-center justify-center space-x-1 rounded-md py-1 px-2 text-xs font-medium text-gray-600 bg-gray-50 ring-gray-500/10 ring-1 ring-inset'
    >
      {text}
    </div>
  )
}

export default Badge
