import { type ApiPlatformEntity } from '@interfaces/api'
import { type Hotel } from '@interfaces/api/hotel'
import { type Offer } from '@interfaces/api/offer'
import { type User } from '@interfaces/api/user'

export enum BookingRequestState {
  accepted = 'accepted',
  cancelled = 'cancelled',
  expired = 'expired',
  proposed = 'proposed',
  requested = 'requested'
}

export interface BookingRequestRoom {
  paxes: Array<{
    age: number
    type: string
  }>
}

export interface BookingRequest extends ApiPlatformEntity {
  adultCount: number
  cancelledAt: string
  checkInDate: string
  checkoutCompletedAt: string
  checkOutDate: string
  checkoutState: string
  childCount: number
  commentForOffer: string
  commentFromAdmin: string
  commentFromUser: string
  createdAt: string
  expiresAt: string
  fulfilledAt: string
  holderFirstName: string
  holderLastName: string
  holderPhone: string
  hotel: Hotel
  mainOffer?: Offer
  paidAt: string
  paymentState: string
  remarkForHotel: string
  roomCount: number
  rooms: BookingRequestRoom[]
  selectedOffer?: Offer
  shippingState: string
  state: BookingRequestState
  uid: string
  user: User
}
