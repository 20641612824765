interface ApiResponseLoginSearchError {
  error: string
  success: 0
}

export interface ApiResponseLoginSearchSuccess {
  endpointUrl: string
  tenantToken: string
}

export type ApiResponseLoginSearch = ApiResponseLoginSearchError | ApiResponseLoginSearchSuccess

export function isApiResponseLoginSearchSuccess (response: ApiResponseLoginSearch): response is ApiResponseLoginSearchSuccess {
  const loginSearchResponse = (response as ApiResponseLoginSearchSuccess)

  return !!(loginSearchResponse.tenantToken)
}
