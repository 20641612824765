import moment from 'moment'
import 'moment/locale/fr'

import { type DateProps } from '@components/api-resource/resource-entry-fields/date/index'

const DateField = ({ datetimeEnabled = false, value }: DateProps) => {
  return (
    <>
      {datetimeEnabled ? moment(value).format('L LT') : moment(value).format('L')}
    </>
  )
}

export default DateField
