import { type Field } from '@interfaces/api/definition/field'

export const isPropertyType = (field: Field, type: string) => {
  if ('type' in field.properties) {
    if (Array.isArray(field.properties.type)) {
      const typeArray = field.properties.type as string[]

      return typeArray.includes(type)
    } else {
      return field.properties.type === type
    }
  }
}
