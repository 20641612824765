// const textGrayClasses = 'text-gray-600 bg-gray-50 ring-gray-500/10 ring-1 ring-inset'
const textGreenClasses = 'text-green-700 bg-green-50 ring-green-600/20 ring-1 ring-inset'
const textRedClasses = 'text-red-700 bg-red-50 ring-red-600/10 ring-1 ring-inset'
const textYellowClasses = 'text-yellow-700 bg-yellow-50 ring-gray-500/10 ring-1'
const textBlueClasses = 'text-white bg-primary'

export const BookingStatuses = {
  accepted: textGreenClasses,
  cancelled: textRedClasses,
  proposed: textBlueClasses,
  requested: textYellowClasses
}
