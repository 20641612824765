import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Form, Formik } from 'formik'

import FormSearchField from '@components/form-fields/form-search-field'
import { type Rate } from '@interfaces/api/availability'
import AvailabilitiesList from '@pages/booking-requests/entry/availabilities-list/availabilities-list'
import { type AvailabilitiesModalProps } from '@pages/booking-requests/entry/availabilities-modal/availabilities-modal.interfaces'

const AvailabilitiesModal = ({ bookingRequest, onAddRate, open, roomNumber, setOpen }: AvailabilitiesModalProps) => {
  const onRateSelect = (rate: Rate, roomCode: string) => {
    onAddRate(rate, roomCode)
    setOpen(false)
  }

  return (
    <Dialog className='relative z-10' onClose={() => {
      setOpen(false)
    }} open={open}
    >
      <DialogBackdrop
        className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out'
        transition
      />

      <div className='fixed inset-0 overflow-hidden'>
        <div className='absolute inset-0 overflow-hidden'>
          <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
            <DialogPanel
              className='pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out'
              transition
            >
              <div className='flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl'>
                <div className='px-4 sm:px-6'>
                  <div className='flex items-start justify-between'>
                    <DialogTitle className='text-base font-semibold leading-6 text-gray-900'>Disponibilités</DialogTitle>

                    <div className='ml-3 flex h-7 items-center'>
                      <button
                        className='relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        onClick={() => {
                          setOpen(false)
                        }}
                        type='button'
                      >
                        <span className='absolute -inset-2.5' />

                        <span className='sr-only'>Close panel</span>

                        <XMarkIcon aria-hidden='true' className='h-6 w-6' />
                      </button>
                    </div>
                  </div>
                </div>

                <div className='relative mt-6 flex-1 px-4 sm:px-6'>
                  <Formik
                    initialValues={{ hotel: bookingRequest.hotel }}
                    onSubmit={() => {}}
                  >
                    {({ values }) => {
                      return (
                        <Form>
                          <FormSearchField
                            emptyStateLabel='- Sélectionner un hôtel'
                            label='Hôtel'
                            name='hotel'
                            required
                            value={values.hotel}
                          />

                          {values.hotel && (
                            <AvailabilitiesList bookingRequest={bookingRequest.uid} hotel={values.hotel.uid} onSelect={onRateSelect} roomNumber={roomNumber} />
                          )}
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default AvailabilitiesModal
