import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'

import { type MeiliSearchArticle, type MeiliSearchResponse } from '@interfaces/api/meili/query'
import { getSearchToken } from '@services/api/axios'
import { useAuthToken, useMeiliToken } from '@services/hooks/auth-token'

interface MeiliSearchQueryParameters {
  indexUid: string
  options?: {
    filters?: string
  }
  q: string
}

const useMeiliSearchQuery = ({ indexUid, options = {}, q }: MeiliSearchQueryParameters): UseQueryResult<MeiliSearchResponse<MeiliSearchArticle>> => {
  const { get: getMeiliToken, getUrl, set: setMeiliToken } = useMeiliToken()
  const { get: getToken } = useAuthToken()
  const queryKey = ['meilisearch', 'search', indexUid, q, options]

  const queryFn = async () => {
    const searchParams = {
      ...options,
      q
    }

    try {
      const response = await axios.post(`${getUrl()}/indexes/${indexUid}/search`, searchParams, {
        headers: {
          Authorization: `Bearer ${getMeiliToken()}`,
          'Content-Type': 'application/json'
        }
      })

      return response.data
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        const { endpointUrl, searchToken } = await getSearchToken(`Bearer ${getToken()}`)
        if (searchToken && endpointUrl) {
          setMeiliToken(searchToken, endpointUrl)

          const response = await axios.post(`${endpointUrl}/indexes/${indexUid}/search`, searchParams, {
            headers: {
              Authorization: `Bearer ${searchToken}`,
              'Content-Type': 'application/json'
            }
          })

          return response.data
        } else {
          throw new Error('Impossible d\'obtenir un nouveau token ou URL.')
        }
      }

      throw error
    }
  }

  const staleTime = 5 * 60 * 1000

  return useQuery({
    queryFn,
    queryKey,
    staleTime
  })
}

export default useMeiliSearchQuery
