import {
  type QueryKey,
  useQuery,
  type UseQueryResult
} from '@tanstack/react-query'

import { type ApiError } from '@interfaces/api/api'
import { type User } from '@interfaces/api/user'
import useAxios from '@services/api/axios'
import { useIsLoggedIn } from '@services/hooks/auth-token'

export const useMe = (trigger = true): UseQueryResult<User, ApiError> => {
  const { get } = useAxios()
  const isLoggedIn = useIsLoggedIn()

  return useQuery<User, ApiError, User, QueryKey>({
    enabled: trigger && isLoggedIn,
    queryFn: async () => {
      return await get<undefined, User>('/api/me')
    },
    queryKey: ['user']
  })
}
