import { type OpenAPIV3_1 } from 'openapi-types'

import { ApiDefinition } from '@services/api/definition/api-definition'
import OpenApiDefinition from '@services/api/definition/docs.json'

export const prefix = '/api/'
export const fieldsBlacklist = ['id', 'uid', 'position', 'formats', 'slug', 'description', 'image', 'isFree', 'verified', 'disabled', '@id', '@type', '@context']

const useApiResource = (name: string) => {
  const apiDefinition = new ApiDefinition(OpenApiDefinition as OpenAPIV3_1.Document)

  return apiDefinition.getResource(name)
}

export default useApiResource
