import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import fr from '@services/translations/fr'

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'fr',

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    lng: 'fr',

    resources: {
      fr
    }
  })
