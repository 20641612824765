import classNames from 'classnames'
import React from 'react'

import { type ButtonProps } from '@components/buttons/button/button.interfaces'
import ButtonLoader from '@components/buttons/button-loader/button-loader'

const Button = ({ children, disabled = false, icon: Icon, isLoading = false, onClick, size = 'default', style, type = 'button' }: ButtonProps) => {
  const buttonStyle = classNames(
    'flex gap-2 items-center rounded-lg font-semibold tracking-wide',
    {
      'bg-slate-800 text-white fill-white hover:bg-gray-600 hover:text-white': !disabled && !isLoading && style !== 'warning' && style !== 'success' && style !== 'secondary',
      'border border-green-500 bg-green-500 text-white fill-green-500 hover:bg-white hover:text-green-500 hover:fill-white': style === 'success',
      'border border-red-500 bg-white text-red-500 fill-red-500 hover:border-red-500 hover:bg-red-500 hover:text-white hover:fill-white': style === 'warning',
      'border border-slate-800 bg-transparent text-slate-800 fill-slate-800 hover:bg-slate-800 hover:text-white hover:fill-white': style === 'secondary',
      'cursor-not-allowed bg-slate-400': disabled || isLoading,
      'cursor-pointer': !disabled && !isLoading,
      'px-2 py-1 text-sm': size === 'small',
      'px-4 py-2 text-md': size === 'default'
    }
  )

  return (
    <button
      className={buttonStyle}
      disabled={disabled || isLoading}
      onClick={onClick}
      type={type}
    >
      {children && children}

      {isLoading && <ButtonLoader dark />}

      {!isLoading && Icon && (
        <Icon className={classNames(
          'flex-none',
          {
            'w-4 h-4': size === 'small',
            'w-6 h-6': size === 'default'
          }
        )}
        />
      )}
    </button>
  )
}

export default Button
