import axios, { type AxiosHeaders, type AxiosRequestConfig, type RawAxiosRequestHeaders } from 'axios'
import jwtDecode from 'jwt-decode'

import { ApiError } from '@interfaces/api/error'
import { type ApiResponseLoginPass, isApiResponseLoginPassSuccess } from '@interfaces/api/login/pass'
import { type ApiResponseLoginSearch, isApiResponseLoginSearchSuccess } from '@interfaces/api/meili/pass'
import { captureException } from '@services/exceptions/capture-exception'
import { useAuthToken, useLogout, useMeiliToken, useRefreshToken } from '@services/hooks/auth-token'

export enum AuthTokens {
  auth = 'captainbooking-admin-authorizationBearer',
  meili = 'captainbooking-admin-meiliToken',
  meiliUrl = 'captainbooking-admin-meiliUrl',
  refresh = 'captainbooking-admin-refreshToken'
}

export interface JWTType {
  exp: number
  iat: number
  uuid: string
}

export async function getSearchToken (authorizationBearer: string) {
  try {
    const { data } = await axios.post<ApiResponseLoginSearch>(
      '/api/meilisearch/createToken',
      {},
      {
        baseURL: process.env.REACT_APP_API_URL ?? 'http://localhost:8080/',
        headers: {
          Authorization: authorizationBearer
        }
      }
    )

    if (isApiResponseLoginSearchSuccess(data)) {
      const { endpointUrl, tenantToken: searchToken } = data

      return {
        endpointUrl,
        searchToken
      }
    } else {
      return {
        error: new Error(data.error)
      }
    }
  } catch (error) {
    return {
      error: error as Error
    }
  }
}

/**
 * Takes a token, and returns a new token with updated
 * `accessToken` and `accessTokenExpires`. If an error occurs,
 * returns the old token and an error property
 */
async function refreshAccessToken (authorizationBearer: string, refToken: string) {
  try {
    const { uuid } = jwtDecode<JWTType>(authorizationBearer)

    const refreshBody = { refresh_token: refToken, uuid }

    const { data } = await axios.post<ApiResponseLoginPass>(
      '/auth/token/refresh',
      refreshBody,
      {
        baseURL: process.env.REACT_APP_API_URL ?? 'http://localhost:8080/'
      }
    )

    if (isApiResponseLoginPassSuccess(data)) {
      const { refresh_token: refreshToken, token: accessToken } = data

      return {
        accessToken,
        refreshToken
      }
    } else {
      return {
        error: new Error(data.message)
      }
    }
  } catch (error) {
    return {
      error: error as Error
    }
  }
}

const useApi = () => {
  const { get: getAuthToken, set: setAuthToken } = useAuthToken()
  const { set: setRefreshToken, value: refreshToken } = useRefreshToken()
  const { set: setMeiliToken } = useMeiliToken()
  const { logout } = useLogout()

  const axiosRequest = async <DATA> ({
    data,
    headers: customHeaders,
    method = 'POST',
    params,
    timeout = 10000,
    url
  }: AxiosRequestConfig): Promise<DATA> => {
    const headers: RawAxiosRequestHeaders = {
      ...customHeaders
    }

    const authorizationBearer = getAuthToken()

    if (authorizationBearer) {
      headers.authorization = `Bearer ${authorizationBearer}`
    }

    return await new Promise((resolve, reject) => {
      axios({
        baseURL: process.env.REACT_APP_API_URL ?? 'http://localhost:8080/',
        data,
        headers,
        method,
        params,
        timeout,
        url
      }).then(
        ({ data }) => {
          resolve(data)
        },
        async (payload) => {
          const originalConfig = payload.config

          if (payload.response) {
            const { response } = payload
            if (response.status === 401 && authorizationBearer && refreshToken && !originalConfig._retry) {
              try {
                originalConfig._retry = true

                const {
                  accessToken,
                  error,
                  refreshToken: refToken
                } = await refreshAccessToken(authorizationBearer, refreshToken)

                if (accessToken && refToken) {
                  setAuthToken(accessToken)
                  setRefreshToken(refToken)

                  const { data: response } = await axios({
                    ...originalConfig,
                    headers: {
                      ...originalConfig.headers,
                      authorization: `Bearer ${accessToken}`
                    }
                  })

                  const { endpointUrl, searchToken } = await getSearchToken(authorizationBearer)
                  if (searchToken && endpointUrl) {
                    setMeiliToken(searchToken, endpointUrl)
                  }

                  resolve(response)
                } else if (error) {
                  await logout()

                  captureException(error)
                }
              } catch (err) {
                await logout()

                captureException(err as Error)
              }
            } else if (response.status === 401) {
              if (response.data.message === ApiError.INVALID_CREDENTIAL || response.data.message === ApiError.PARTNER_NOT_VERIFIED || response.data.message === ApiError.PARTNER_NOT_VALIDATED) {
                reject(response)
              } else {
                await logout()
              }
            } else {
              reject(response)
            }
          } else {
            reject(payload)
          }
        }
      )
    })
  }

  const del = async <RESPONSE_DATA> (
    url: string,
    timeout?: number
  ) =>
    await axiosRequest<RESPONSE_DATA>({
      method: 'DELETE',
      timeout,
      url
    })

  const put = async <BODY, RESPONSE_DATA> (
    url: string,
    body: BODY,
    timeout?: number
  ) =>
    await axiosRequest<RESPONSE_DATA>({
      data: body,
      method: 'PUT',
      timeout,
      url
    })

  const patch = async <BODY, RESPONSE_DATA> (
    url: string,
    body: BODY,
    timeout?: number
  ) =>
    await axiosRequest<RESPONSE_DATA>({
      data: body,
      headers: {
        'content-type': 'application/merge-patch+json'
      },
      method: 'PATCH',
      timeout,
      url
    })

  const post = async <BODY, RESPONSE_DATA> (
    url: string,
    body: BODY,
    headers?: AxiosHeaders,
    timeout?: number
  ) =>
    await axiosRequest<RESPONSE_DATA>({
      data: body,
      headers,
      method: 'POST',
      timeout,
      url
    })

  const postFile = async <BODY, RESPONSE_DATA> (
    url: string,
    body: BODY,
    timeout?: number
  ) =>
    await axiosRequest<RESPONSE_DATA>({
      data: body,
      headers: {
        accept: 'application/ld+json',
        'content-type': 'multipart/form-data'
      },
      method: 'POST',
      timeout,
      url
    })

  const get = async <QUERY_PARAMS, RESPONSE_DATA> (
    url: string,
    params?: QUERY_PARAMS,
    timeout?: number
  ) =>
    await axiosRequest<RESPONSE_DATA>({
      method: 'GET',
      params,
      timeout,
      url
    })

  return { axiosRequest, del, get, patch, post, postFile, put }
}

export default useApi
