import { ListBulletIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layouts/layout/layout'
import SectionTitle from '@components/section-title/section-title'
import changelogData from '@root/changelog.json'
const Changelog = () => {
  const { t: translateGeneral } = useTranslation('general', { keyPrefix: 'changelog' })

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('fr-FR')
  }

  return (
    <Layout title={translateGeneral('title')}>
      <Breadcrumbs />

      <Guard>
        <div className='mb-4 mx-4 sm:mx-6 md:mx-8'>
          <SectionTitle description={translateGeneral('description')} icon={ListBulletIcon} title={translateGeneral('title')} />

          <div className='flex flex-col p-8'>
            {changelogData.map((entry, index) => (
              <div className='mb-8' key={index}>
                <h2 className='text-lg font-semibold text-gray-700 mb-4'>
                  {`Version ${entry.version} - `}

                  <span className='text-sm text-gray-600'>{formatDate(entry.date)}</span>
                </h2>

                <div className='border-t border-gray-300'>
                  <ul className='list-disc list-inside text-gray-700 mt-4'>
                    {entry.changes.map((change, changeIndex) => (
                      <li className='mb-2' key={changeIndex}>{change}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Guard>
    </Layout>
  )
}

export default Changelog
