import { HomeIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import { type BreadcrumbsProps } from '@components/breadcrumbs/breadcrumbs-interfaces'

const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  const { t: translateGeneral } = useTranslation('general', { keyPrefix: 'sidebar' })

  if (!breadcrumbs || breadcrumbs?.length <= 0) {
    return <></>
  }

  return (
    <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8 w-full'>
      <nav aria-label='Breadcrumb' className='flex'>
        <ol className='flex space-x-4 rounded-md bg-white px-6 shadow'>
          <li className='flex'>
            <div className='flex items-center'>
              <a className='text-gray-400 hover:text-gray-500 px-1 py-2.5' href='/'>
                <HomeIcon aria-hidden='true' className='h-5 w-5 flex-shrink-0' />

                <span className='sr-only'>{translateGeneral('home')}</span>
              </a>
            </div>
          </li>

          {breadcrumbs?.map((breadcrumb, index) => (
            <li className='flex' key={index}>
              <div className='flex items-center'>
                <svg
                  aria-hidden='true'
                  className='h-full w-6 flex-shrink-0 text-gray-200'
                  fill='currentColor'
                  preserveAspectRatio='none'
                  viewBox='0 0 24 44'
                >
                  <path d='M.293 0l22 22-22 22h1.414l22-22-22-22H.293z' />
                </svg>

                <a
                  className='ml-4 text-sm font-medium text-gray-500 hover:text-gray-700'
                  href={breadcrumb.href}
                >
                  {breadcrumb.name}
                </a>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  )
}

export default Breadcrumbs
