import {
  useMutation,
  type UseMutationResult
} from '@tanstack/react-query'

import { type ApiRequestLoginPass, type ApiResponseLoginPass, isApiResponseLoginPassSuccess } from '@interfaces/api/login/pass'
import useAxios from '@services/api/axios'
import { useAuthToken, useRefreshToken } from '@services/hooks/auth-token'

export const useSignInMutation = (): UseMutationResult<ApiResponseLoginPass, unknown, ApiRequestLoginPass> => {
  const { post } = useAxios()
  const { set: setAuthToken } = useAuthToken()
  const { set: setRefreshToken } = useRefreshToken()

  return useMutation<ApiResponseLoginPass, unknown, ApiRequestLoginPass>({
    mutationFn: async (data) =>
      await post<ApiRequestLoginPass, ApiResponseLoginPass>('/auth/login', data),
    onSuccess: (data) => {
      if (isApiResponseLoginPassSuccess(data)) {
        setAuthToken(data.token)
        setRefreshToken(data.refresh_token)
      }
    }
  })
}
