import { UsersIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layouts/layout/layout'
import SectionTitle from '@components/section-title/section-title'

const UsersList = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'users' })

  const breadcrumbs = [
    { href: '', name: translateResource('title') }
  ]

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={UsersIcon} title={translateResource('title')} />

        <ApiResource createdAt={'desc'} customFieldsToDisplay={['email', 'isVerified', 'isAdmin', 'roles', 'createdAt', 'lastLoginAt']} name={'users'} />
      </Guard>
    </Layout>
  )
}

export default UsersList
