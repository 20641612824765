import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { BookingRequestInfos } from '@components/booking-request-infos'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layouts/layout/layout'
import type { BookingRequest } from '@interfaces/api/booking-request/booking-request'
import { type Offer } from '@interfaces/api/offer'
import OfferForm from '@pages/booking-requests/offer-form/offer-form'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

const OfferEdit = () => {
  const { id = '', offerId = '' } = useParams()
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'booking-requests' })

  const {
    data: bookingRequest
  } = useApiResourceItemQuery<BookingRequest>({ id, path: 'booking-requests/{uid}', refetchOnMount: 'always' })

  const {
    data: offer
  } = useApiResourceItemQuery<Offer>({ id: offerId, path: 'offers/{uid}', refetchOnMount: 'always' })

  const breadcrumbs = [
    { href: '', name: translateResource('title') },
    { href: `/booking-requests/${bookingRequest?.uid}`, name: `Demande ${bookingRequest?.user?.email ?? ''}` },
    { href: '', name: `Offre ${offer?.label ?? ''}` }
  ]

  const { mutateAsync: patchOffer } = usePatchEntrySimple({ path: 'offers/{uid}' })

  const onOfferSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true)

    try {
      if (bookingRequest) {
        await patchOffer({
          data: {
            externalPrices: {
              booking: values.externalPrices.booking * 100,
              expedia: values.externalPrices.expedia * 100
            },
            label: values.label
          },
          id: offerId
        })

        // TODO REFETCH OFFERS
        resetForm()
      }
    } catch (error) {
      const err = error as Error
      captureException(err)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} - ${bookingRequest?.user.email ?? ''}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mx-auto px-4 sm:px-6 lg:px-8'>
          {bookingRequest && offer && (
            <div className='grid grid-cols-4 gap-4'>

              <div className='col-span-3 space-y-4'>
                <div className='bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden'>
                  <OfferForm
                    bookingRequest={bookingRequest}
                    editMode={true}
                    offer={offer}
                    onSubmit={onOfferSubmit}
                  />
                </div>
              </div>

              <div>
                <div className='bg-white rounded-lg border border-gray-200 shadow-sm p-4 text-sm space-y-6'>
                  <BookingRequestInfos bookingRequest={bookingRequest} />
                </div>
              </div>

            </div>
          )}
        </div>
      </Guard>
    </Layout>
  )
}

export default OfferEdit
