import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@components/buttons/button'
import Modal from '@components/modals/modal'
import { type ModalConfirmDeleteProps } from '@components/modals/modal-confirm-delete/modal-confirm-delete.interfaces'

const ModalConfirmDelete = ({ isLoading, message, onConfirm, open, setOpen, title }: ModalConfirmDeleteProps) => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })

  return (
    <Modal center open={open} setOpen={setOpen} title={title}>
      <div className='flex flex-col items-center p-8'>
        <div className='text-center text-xl'>{message}</div>

        <div className='flex mt-6 gap-8'>
          <Button isLoading={isLoading} onClick={onConfirm} style='warning'>{translateActions('confirm')}</Button>

          <Button disabled={isLoading} onClick={() => {
            setOpen(false)
          }}
          >
            {translateActions('cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalConfirmDelete
