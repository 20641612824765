import {
  useMutation
} from '@tanstack/react-query'

import { type ResourceItemDataParameters } from '@interfaces/api/definition/resource'
import useAxios from '@services/api/axios'

const usePatchEntrySimple = ({ path }) => {
  const { patch } = useAxios()

  return useMutation({
    mutationFn: async ({ data, id }: ResourceItemDataParameters) => {
      let url = `api/${path.replace('{uid}', id)}`

      if (path.includes('{id}')) {
        url = `api/${path.replace('{id}', id)}`
      }

      return await patch<never, never>(url, data as never)
    }
  })
}

export default usePatchEntrySimple
