import { PhotoIcon } from '@heroicons/react/24/outline'

import { type ImageEmptyStateProps } from '@components/empty-states/image-empty-state/image-empty-state.interfaces'

const ImageEmptyState = ({ classNames, iconClassNames }: ImageEmptyStateProps) => {
  return (
    <div className={classNames ?? 'w-24 h-16 rounded-lg overflow-hidden bg-slate-400 flex items-center justify-center'}>
      <PhotoIcon className={iconClassNames ?? 'h-8 w-8 text-white'} />
    </div>
  )
}

export default ImageEmptyState
