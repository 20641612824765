import { ArrowRightCircleIcon } from '@heroicons/react/24/outline'
import { ErrorMessage, Field, Form, Formik, type FormikConfig } from 'formik'
import { useState } from 'react'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import * as Yup from 'yup'

import Button from '@components/buttons/button'
import LayoutLogin from '@components/layouts/layout-login/layout-login'
import { useSignInMutation } from '@services/api/auth/use-sign-in-mutation'
import { useSearchToken } from '@services/api/search/use-search-token'
import { captureException } from '@services/exceptions/capture-exception'

const FormSchema = Yup.object({
  email: Yup.string().email('Format d\'email incorrect').required('Requis'),
  password: Yup.string().required('Requis')
})

interface FromState {
  from: {
    pathname: string
  }
}

const Page = () => {
  const { t: translateGeneral } = useTranslation('general')
  const [errorMsg, setErrorMsg] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const { mutateAsync: signIn } = useSignInMutation()
  const { mutateAsync: getToken } = useSearchToken()

  const onSubmit: FormikConfig<{ email: string; password: string }>['onSubmit'] = async (values, { setSubmitting }) => {
    setSubmitting(true)

    try {
      const result = await signIn(values)
      setSubmitting(false)

      if ('error' in result) {
        setErrorMsg('Mauvais identifiant ou mot de passe')
      } else {
        await getToken({})
        navigate((location.state as FromState | undefined)?.from?.pathname ?? '/', { replace: true })
      }
    } catch (err) {
      captureException(err as Error)

      setErrorMsg('Mauvais identifiant ou mot de passe')
    }
  }

  return (
    <>
      <Helmet>
        <title>{`${translateGeneral('login')} - Captain Booking`}</title>
      </Helmet>

      <LayoutLogin>
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          onSubmit={onSubmit}
          validationSchema={FormSchema}
        >
          {({ isSubmitting }) => {
            return (
              <Form className='space-y-6 px-4 py-3 rounded-lg'>
                <div className='flex flex-col'>
                  <label className='block font-medium text-gray-700' htmlFor='email'>{translateGeneral('email')}</label>

                  <Field
                    autoComplete='email'
                    className='appearance-none block w-full mt-2 px-3 py-2.5 border-2 border-gray-100 rounded-lg placeholder-gray-400 focus:outline-none'
                    name='email'
                    type='email'
                  />

                  <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name='email' />
                </div>

                <div className='flex flex-col'>
                  <label className='block font-medium text-gray-700' htmlFor='password'>{translateGeneral('password')}</label>

                  <Field
                    autoComplete='current-password'
                    className='appearance-none block w-full mt-1 px-3 py-2.5 border-2 border-gray-100 rounded-lg placeholder-gray-400 focus:outline-none'
                    name='password'
                    type='password'
                  />

                  <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name='password' />
                </div>

                <div className='text-red-500 text-xs font-medium'>{errorMsg}</div>

                <Button
                  icon={ArrowRightCircleIcon}
                  isLoading={isSubmitting}
                  type='submit'
                >
                  {translateGeneral('login')}
                </Button>

                {/**
                <div className='text-sm leading-6 text-center w-full'>
                  <a className='font-medium text-slate-800 hover:text-slate-500' href='/forgot-password'>
                    {translateGeneral('forgotPasswordLink')}
                  </a>
                </div>
                 **/}
              </Form>
            )
          }}
        </Formik>
      </LayoutLogin>
    </>
  )
}

export default Page
