import apiResources from '@services/translations/fr/api-resources.json'
import countries from '@services/translations/fr/countries.json'
import errors from '@services/translations/fr/errors.json'
import general from '@services/translations/fr/general.json'

const translation = {
  apiResources,
  countries,
  errors,
  general
}

export default translation
