import {
  DocumentDuplicateIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { pluralize } from 'inflection'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type ApiResourceListEntryActionsProps
} from '@components/api-resource/api-resource-lists/api-resource-list-entry-actions/api-resource-list-entry-actions.interfaces'
import Button from '@components/buttons/button'
import TooltipButton from '@components/buttons/tooltip-button/tooltip-button'
import Modal from '@components/modals/modal'
import { type ApiPlatformEntity } from '@interfaces/api'
import useCloneEntry from '@services/api/resources/clone-entry-query'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

const ApiResourceListEntryActions = <T extends ApiPlatformEntity>({ definition, entry, identifierAttribute = 'uid', onReload, withCloneOption = false, withCopyOption, withDeleteOption = false, withEditOption }: ApiResourceListEntryActionsProps<T>) => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openCloneModal, setOpenCloneModal] = useState<boolean>(false)

  let path = `${definition.url}`
  if (definition.name === 'experiences' && 'type' in entry) {
    path = pluralize(entry.type as string)
  }

  const { mutateAsync: deleteResourceEntry } = useDeleteEntrySimple({ path })
  const { mutateAsync: cloneResourceEntry } = useCloneEntry(`api/${definition.url}/clone`)

  const handleDeleteClick = (e) => {
    e.stopPropagation()
    setOpenDeleteModal(true)
  }

  const handleCloneClick = (e) => {
    e.stopPropagation()
    setOpenCloneModal(true)
  }

  const handleEditClick = (e) => {
    e.stopPropagation()
    withEditOption?.(entry)
  }

  const handleCancelClick = () => {
    setOpenDeleteModal(false)
    setOpenCloneModal(false)
  }

  const handleCopyClick = () => {
    withCopyOption?.(entry)
  }

  const onDeleteHandler = () => {
    deleteResourceEntry({ id: entry[identifierAttribute] }).then(() => {
      onReload()
      setOpenDeleteModal(false)
    }).catch(captureException)
  }

  const onCloneHandler = () => {
    cloneResourceEntry({ original: entry['@id'] }).then(() => {
      onReload()
      setOpenCloneModal(false)
    }).catch(captureException)
  }

  return (
    <div className='flex items-center gap-4'>
      {withCloneOption && (
        <>
          <button
            className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
            onClick={handleCloneClick}
          >
            <DocumentDuplicateIcon className='w-5 h-5 mx-auto' />
          </button>

          <Modal center open={openCloneModal} setOpen={setOpenCloneModal} title={translateActions('duplicateResource')}>
            <div className='flex flex-col items-center p-8'>
              <div className='text-center text-xl'>{translateActions('duplicateConfirmation')}</div>

              <div className='flex mt-6 gap-8'>
                <Button onClick={onCloneHandler}>
                  {translateActions('clone')}
                </Button>

                <Button onClick={handleCancelClick}>{translateActions('cancel')}</Button>
              </div>
            </div>
          </Modal>
        </>
      )}

      {withCopyOption && (
        <TooltipButton handleCopyClick={handleCopyClick} />
      )}

      {withEditOption && (
        <>
          <button
            className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
            onClick={handleEditClick}
          >
            <PencilSquareIcon className='w-5 h-5 mx-auto' />
          </button>
        </>
      )}

      {withDeleteOption && (
        <>
          <button
            className='flex items-center text-gray-700 fill-gray-700 group-hover:hover:fill-white gap-2 py-1 px-2 group-hover:hover:bg-red-500 group-hover:hover:border-red-500 group-hover:hover:text-white text-sm rounded-md hover:text-red-700 bg-gray-100 cursor-pointer hover:bg-white hover:border-red-700 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
            onClick={handleDeleteClick}
          >
            <TrashIcon className='w-5 h-5 mx-auto' />
          </button>

          <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
            <div className='flex flex-col items-center p-8'>
              <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

              <div className='flex mt-6 gap-8'>
                <Button onClick={onDeleteHandler}
                  style='warning'
                >
                  {translateActions('delete')}
                </Button>

                <Button onClick={handleCancelClick}>{translateActions('cancel')}</Button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  )
}

export default ApiResourceListEntryActions
