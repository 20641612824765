import { ErrorMessage, Field } from 'formik'

import { type FormTextFieldProps } from '@components/form-fields/form-text-field/index'

const FormTextField = ({ label, name, placeholder, required = false, type = 'text' }: FormTextFieldProps) => {
  return (
    <div className='flex flex-col w-full gap-2'>
      {label && (
        <label className='block font-medium text-gray-900 text-sm' htmlFor={name}>
          {label}

          {required && <sup className='text-gray-500 font-normal'>*</sup>}
        </label>
      )}

      <Field
        className='appearance-none block w-full px-3 py-1.5 border border-gray-300 bg-gray-50 rounded-lg placeholder-gray-500 focus:outline-none'
        name={name}
        placeholder={placeholder}
        required={required}
        type={type}
      />

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormTextField
