import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PaginationItemsPerPage from '@components/api-resource/api-resource-lists/pagination/pagination-items-per-page'
import { type PaginationProps } from '@components/api-resource/api-resource-lists/pagination/pagination.interfaces'

const Pagination = ({ isTop, itemsPerPage = 30, pageIndex, pagination, setItemsPerPage, setPageIndex, totalItems }: PaginationProps) => {
  const { t: translateGeneral } = useTranslation('general')
  const [lastPage, setLastPage] = useState(1)
  const [firstPage, setFirstPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  const baseButtonClass = 'relative inline-flex items-center px-4 py-2 text-sm font-medium ring-1 ring-inset ring-gray-300'
  const activeButtonClass = 'bg-black text-white hover:bg-black hover:text-white'
  const inactiveButtonClass = 'text-gray-900 bg-white hover:bg-gray-50'
  const edgeButtonClass = 'px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
  const ellipsisClass = 'text-gray-700 bg-white'

  const extractPageNumber = (url: string): number => {
    if (!url) {
      return 0
    }
    const urlObj = new URL(url, process.env.REACT_APP_API_URL)
    const page = new URLSearchParams(urlObj.search).get('page')

    return page ? parseInt(page, 10) : 1
  }

  useEffect(() => {
    const last = pagination['hydra:last']
    const first = pagination['hydra:first']
    const current = pagination['@id']
    if (last && first && current) {
      setCurrentPage(extractPageNumber(current))
      setLastPage(extractPageNumber(last))
      setFirstPage(extractPageNumber(first))
    }
  }, [pagination])

  const goToPrevious = () => {
    if (pageIndex - 1 >= firstPage) {
      setPageIndex(pageIndex - 1)
    }
  }

  const goToNext = () => {
    if (pageIndex + 1 <= lastPage) {
      setPageIndex(pageIndex + 1)
    }
  }

  const goToPage = (pageNumber: number) => () => {
    setPageIndex(pageNumber)
  }

  const onItemsPerPageChange = (itemsPerPage: number) => {
    if (setItemsPerPage) {
      setItemsPerPage(itemsPerPage)
    }
  }

  const renderPages = () => {
    const pages: React.ReactNode[] = []
    let endPage, startPage

    if (lastPage <= 5) {
      startPage = 1
      endPage = lastPage
    } else {
      if (currentPage <= 3) {
        startPage = 1
        endPage = 5
      } else if (currentPage + 2 >= lastPage) {
        startPage = lastPage - 4
        endPage = lastPage
      } else {
        startPage = currentPage - 2
        endPage = currentPage + 2
      }
    }

    if (startPage > 1) {
      pages.push(
        <button
          className={`${baseButtonClass} ${inactiveButtonClass}`}
          key='1'
          onClick={goToPage(1)}
          type='button'
        >
          1
        </button>
      )
      if (startPage > 2) {
        pages.push(
          <span
            className={`${baseButtonClass} ${ellipsisClass}`}
            key='ellipsis-start'
          >
            ...
          </span>
        )
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          aria-current={currentPage === i ? 'page' : undefined}
          className={`${baseButtonClass} ${currentPage === i ? activeButtonClass : inactiveButtonClass}`}
          key={i}
          onClick={goToPage(i)}
          type='button'
        >
          {i}
        </button>
      )
    }

    if (endPage < lastPage) {
      if (endPage < lastPage - 1) {
        pages.push(
          <span
            className={`${baseButtonClass} ${ellipsisClass}`}
            key='ellipsis-end'
          >
            ...
          </span>
        )
      }
      pages.push(
        <button
          className={`${baseButtonClass} ${inactiveButtonClass}`}
          key={lastPage}
          onClick={goToPage(lastPage)}
          type='button'
        >
          {lastPage}
        </button>
      )
    }

    return pages
  }

  const startIndex = (pageIndex - 1) * itemsPerPage + 1
  const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems)

  return (
    <div className={`flex items-center justify-between ${isTop ? 'rounded-t-md' : 'rounded-b-md'} bg-white px-4 py-3 sm:px-6`}>
      <div className='hidden sm:flex sm:flex-1 sm:items-center sm:justify-between'>
        <div>
          {totalItems > 0 && (
            <p className='text-sm text-gray-700'>
              Affichage de
              {' '}

              <span className='font-medium'>{startIndex}</span>

              {' '}
              à
              {' '}

              <span className='font-medium'>{endIndex}</span>

              {' '}
              sur
              {' '}

              <span className='font-medium'>{totalItems}</span>

              {' '}
              éléments
            </p>
          )}
        </div>

        {itemsPerPage && setItemsPerPage && itemsPerPage !== -1 && (
          <div>
            <PaginationItemsPerPage itemsPerPage={itemsPerPage} onItemsPerPageChange={onItemsPerPageChange} />
          </div>
        )}

        <div className='flex justify-end'>
          <nav aria-label='Pagination' className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'>
            <button
              className={`${edgeButtonClass} ${inactiveButtonClass} rounded-l-md`}
              onClick={goToPrevious}
              type='button'
            >
              <span className='sr-only'>{translateGeneral('previous')}</span>

              <ChevronLeftIcon aria-hidden='true' className='h-5 w-5' />
            </button>

            {renderPages()}

            <button
              className={`${edgeButtonClass} ${inactiveButtonClass} rounded-r-md`}
              onClick={goToNext}
              type='button'
            >
              <span className='sr-only'>{translateGeneral('next')}</span>

              <ChevronRightIcon aria-hidden='true' className='h-5 w-5' />
            </button>
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Pagination
