import classNames from 'classnames'
import React from 'react'

import { type IconButtonProps } from '@components/buttons/icon-button/index'

const IconButton = ({ className, disabled = false, icon: Icon, onClick, size = 4, style }: IconButtonProps) => {
  const onButtonClick = (e) => {
    e.stopPropagation()
    onClick()
  }

  return (
    <button
      className={classNames(
        'flex items-center rounded-md border hover:fill-white hover:text-white p-1 border-gray-200',
        {
          [`${className}`]: true,
          'hover:border-gray-900 hover:bg-gray-800': style !== 'warning' && style !== 'positive',
          'hover:border-green-900 hover:bg-green-700': style === 'positive',
          'hover:border-red-500 hover:bg-red-500': style === 'warning',
          'opacity-30': disabled
        }
      )}
      disabled={disabled}
      onClick={onButtonClick}
    >
      <Icon className={`w-${size} h-${size}`} />
    </button>
  )
}

export default IconButton
