import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { Fragment, useEffect, useState } from 'react'

import { type PaginationItemsPerPageProps } from '@components/api-resource/api-resource-lists/pagination/pagination-items-per-page.interfaces'

export const itemsPerPageOptions = [
  100, 200
]
const PaginationItemsPerPage = ({ itemsPerPage, onItemsPerPageChange }: PaginationItemsPerPageProps) => {
  const [selectedOption, setSelectedOption] = useState(itemsPerPageOptions[0])

  useEffect(() => {
    setSelectedOption(itemsPerPage)
  }, [itemsPerPage])

  return (
    <div className='flex items-center space-x-2'>
      <span>Éléments par page :</span>

      <Listbox onChange={(newValue) => {
        setSelectedOption(newValue)
        onItemsPerPageChange(newValue)
      }} value={selectedOption}
      >
        {({ open }) => (
          <>
            <div className='relative mt-1'>
              <Listbox.Button className='relative w-20 rounded-md cursor-default border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none sm:text-sm'>
                <span className='block truncate'>{selectedOption}</span>

                <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                  <ChevronUpDownIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />
                </span>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
                show={open}
              >
                <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                  {itemsPerPageOptions.map((option) => (
                    <Listbox.Option
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-3 pr-9 ${active ? 'bg-indigo-100 text-indigo-900' : 'text-gray-900'}`
                      }
                      key={option}
                      value={option}
                    >
                      {({ selected }) => (
                        <>
                          <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                            {option}
                          </span>
                          {selected && (
                            <span className='absolute inset-y-0 right-0 flex items-center pr-4'>
                              <CheckIcon aria-hidden='true' className='h-5 w-5 text-indigo-600' />
                            </span>
                          )}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}

export default PaginationItemsPerPage
