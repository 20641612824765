import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import 'moment/locale/fr'

import { BookingRequestInfos } from '@components/booking-request-infos'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Button from '@components/buttons/button'
import Guard from '@components/guard/guard'
import Layout from '@components/layouts/layout/layout'
import ModalConfirmDelete from '@components/modals/modal-confirm-delete/modal-confirm-delete'
import { type BookingRequest } from '@interfaces/api/booking-request/booking-request'
import { type Offer } from '@interfaces/api/offer'
import { BookingRequestHeader } from '@pages/booking-requests/entry/booking-request-header'
import OffersTable from '@pages/booking-requests/entry/offers-table/offers-table'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useItemChildrenQuery from '@services/api/resources/item-children'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

const BookingEntry = () => {
  const { id = '' } = useParams()
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'booking-requests' })
  const navigate = useNavigate()

  const {
    data: bookingRequest,
    refetch: refetchBookingRequest
  } = useApiResourceItemQuery<BookingRequest>({ id, path: 'booking-requests/{uid}', refetchOnMount: 'always' })

  const breadcrumbs = [
    { href: '/booking-requests', name: translateResource('title') },
    { href: `/booking-requests/${bookingRequest?.uid}`, name: `Demande ${bookingRequest?.user?.email ?? ''}` }
  ]

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [offerToDelete, setOfferToDelete] = useState<string>('')
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false)

  const {
    data: {
      data: offers
    } = {},
    refetch: refetchOffers
  } = useItemChildrenQuery<Offer>({
    itemId: id,
    path: 'booking-requests/{uid}/offers'
  })

  const { mutateAsync: deleteOffer } = useDeleteEntrySimple({ path: 'offers/{uid}' })
  const { mutateAsync: patchBookingRequest } = usePatchEntrySimple({ path: 'booking-requests/{uid}' })

  const onAddOfferClick = () => {
    navigate(`/booking-requests/${id}/add-offer`)
  }

  const onEditOffer = (offer: Offer) => {
    navigate(`/booking-requests/${id}/offer/${offer.uid}`)
  }

  const onDeleteOffer = (uid: string) => {
    setOpenDeleteModal(true)
    setOfferToDelete(uid)
  }

  const handleDeleteOffer = () => {
    setIsLoadingDelete(true)
    deleteOffer({ id: offerToDelete }).then(() => {
      setOpenDeleteModal(false)
      setIsLoadingDelete(false)
      refetchOffers().catch(captureException)
    }).catch(captureException)
  }

  const onToggleMain = async (offerId: string) => {
    await patchBookingRequest({
      data: {
        mainOffer: offerId
      },
      id
    })
    refetchBookingRequest().catch(captureException)
  }

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} - ${bookingRequest?.user.email ?? ''}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        {bookingRequest && offers
          ? (
            <div className='mx-auto px-4 sm:px-6 lg:px-8'>

              <BookingRequestHeader bookingRequest={bookingRequest} offers={offers} />

              <div className='grid grid-cols-4 gap-4'>

                <div className='col-span-3 space-y-4'>
                  <div className='bg-white rounded-lg border border-gray-200 shadow-sm p-4'>
                    {offers && offers.length > 0 && (
                      <OffersTable mainOffer={bookingRequest.mainOffer} offers={offers} onDeleteOffer={onDeleteOffer} onEditOffer={onEditOffer} toggleMain={onToggleMain} />
                    )}

                    <div className='flex justify-end mt-6'>
                      <Button onClick={onAddOfferClick} style={'secondary'}>Ajouter une offre</Button>
                    </div>
                  </div>
                </div>

                <div>
                  <div className='bg-white rounded-lg border border-gray-200 shadow-sm p-4 text-sm space-y-6'>
                    <BookingRequestInfos bookingRequest={bookingRequest} />
                  </div>
                </div>

              </div>
            </div>
          )
          : <></>}

        <ModalConfirmDelete
          isLoading={isLoadingDelete}
          message={'Êtes-vous sûr de vouloir supprimer cette offre ?'}
          onConfirm={handleDeleteOffer}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          title={'Suppression d\'une offre'}
        />
      </Guard>
    </Layout>
  )
}

export default BookingEntry
