import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline'

import { type SectionTitleProps } from '@components/section-title/section-title-interfaces'

const SectionTitle = ({ description, icon: Icon, title }: SectionTitleProps) => {
  return (
    <div className='flex gap-4 items-center px-4 sm:px-6 md:px-8 mb-6'>
      {Icon && (
        <div className='p-2 bg-black rounded-full'>
          <Icon className={`size-5 text-white ${Icon === ChatBubbleOvalLeftIcon ? 'stroke-white' : ''}`} />
        </div>
      )}

      <div className='flex flex-col'>
        <div className='font-semibold text-black text-3xl flex gap-4 items-center'>
          {title}
        </div>

        {description && (
          <div className='text-gray-500 text-lg font-light'>{description}</div>
        )}
      </div>
    </div>
  )
}

export default SectionTitle
