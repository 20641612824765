import { useQuery, type UseQueryResult } from '@tanstack/react-query'

import { type ResourceItemParameters } from '@interfaces/api/definition/resource'
import useAxios from '@services/api/axios'

const useApiResourceItemQuery = <T>({ id, path, refetchOnMount = true, ...props }: ResourceItemParameters): UseQueryResult<T> => {
  const { get } = useAxios()
  let url = `api/${path.replace('{uid}', id)}`

  if (path.includes('{id}')) {
    url = `api/${path.replace('{id}', id)}`
  }

  const queryKey = [url]
  const queryFn = async () => {
    return await get(url).then(result => result)
  }
  const staleTime = 5 * 60 * 1000

  // TODO: Talal will fix it later
  // @ts-expect-error types not
  return useQuery({
    enabled: id !== '',
    queryFn,
    queryKey,
    refetchOnMount,
    staleTime,
    ...props
  })
}

export default useApiResourceItemQuery
