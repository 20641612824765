import { type OpenAPIV3_1 } from 'openapi-types'

import { prefix } from '@services/api/definition'
import { Resource } from '@services/api/definition/resource'

export class ApiDefinition {
  resources: Resource[]
  paths?: OpenAPIV3_1.PathsObject
  schemas: Record<string, OpenAPIV3_1.ReferenceObject | OpenAPIV3_1.SchemaObject>

  constructor (definition: OpenAPIV3_1.Document) {
    this.resources = []
    this.paths = definition.paths
    this.schemas = definition.components?.schemas ?? {}
  }

  getResource (resourcePath: string) {
    if (!this.paths) {
      return
    }

    const resource = Object.entries(this.paths).find(([path, definition]) => {
      return path.replace(prefix, '') === resourcePath
    })
    if (resource) {
      let [path, definition] = resource
      path = path.replace(prefix, '')

      return new Resource(path, definition as OpenAPIV3_1.PathItemObject, this)
    } else {
      console.error(`Cannot find ${resourcePath} in`, this.paths)

      return null
    }
  }
}
