import { useMutation, type UseMutationResult } from '@tanstack/react-query'

import { type ApiResponseLoginSearch, isApiResponseLoginSearchSuccess } from '@interfaces/api/meili/pass'
import useAxios from '@services/api/axios'
import { useMeiliToken } from '@services/hooks/auth-token'

export const useSearchToken = (): UseMutationResult<ApiResponseLoginSearch, unknown> => {
  const { post } = useAxios()
  const { set: setMeiliToken } = useMeiliToken()

  return useMutation<ApiResponseLoginSearch, unknown, unknown>({
    mutationFn: async () =>
      await post<unknown, ApiResponseLoginSearch>('api/meilisearch/createToken', {}),
    onSuccess: (data) => {
      if (isApiResponseLoginSearchSuccess(data)) {
        setMeiliToken(data?.tenantToken, data?.endpointUrl)
      }
    }
  })
}
