import {
  CheckIcon,
  UserIcon, XMarkIcon
} from '@heroicons/react/24/outline'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { type ApiResourceListEntryProps } from '@components/api-resource/api-resource-lists/api-resource-list-entry'
import ApiResourceListEntryActions from '@components/api-resource/api-resource-lists/api-resource-list-entry-actions'
import Date from '@components/api-resource/resource-entry-fields/date'
import Duration from '@components/api-resource/resource-entry-fields/duration'
import ImageEmptyState from '@components/empty-states/image-empty-state/image-empty-state'
import StatusTag from '@components/status-tag/status-tag'
import { type ApiPlatformEntity } from '@interfaces/api'
import { type Country } from '@interfaces/api/country'
import { type Image } from '@interfaces/api/image'
import { type User } from '@interfaces/api/user'
import { isPropertyType } from '@services/tools/api-resources/property-types'

const ApiResourceListEntry = <T extends ApiPlatformEntity>({ definition, entry, fieldsToDisplay, identifierAttribute = 'uid', onClick, onReload, withCloneOption = false, withCopyOption, withDeleteOption = false, withEditOption }: ApiResourceListEntryProps<T>) => {
  const { t: translateCountry } = useTranslation('countries')
  const { t: translateRoles } = useTranslation('apiResources', { keyPrefix: 'roles' })

  const fields: ReactElement[] = []
  const onClickHandler = () => {
    if (onClick) {
      onClick(entry)
    }
  }

  fieldsToDisplay.forEach((field, index) => {
    if (entry[field.name] !== undefined) {
      if ('type' in field.properties) {
        if (isPropertyType(field, 'string')) {
          if (field.properties.format === 'date-time' && entry[field.name]) {
            fields.push(<Date datetimeEnabled value={entry[field.name]} />)
          } else if (field.name === 'status') {
            fields.push(
              <StatusTag status={entry[field.name]} />
            )
          } else if (field.name === 'country' || field.name === 'nationality') {
            fields.push(<div key={index}>{translateCountry(entry[field.name]) ?? '-'}</div>)
          } else if (field.name === 'name') {
            fields.push(
              <div className='flex gap-4' key={index}>
                <>
                  {'cover' in entry && entry?.cover &&
                        (
                          <>
                            {(entry.cover as Image).url
                              ? (
                                <div className='w-24 h-16 rounded-lg overflow-hidden bg-gray-500'>
                                  <img alt='preview' className='w-full h-full object-cover' src={(entry.cover as Image).url} />
                                </div>
                              )
                              : <ImageEmptyState />}
                          </>
                        )}
                </>

                <div className='flex flex-col justify-center'>
                  <div className='text-lg font-normal text-gray-700'>{entry[field.name]}</div>
                </div>
              </div>
            )
          } else if (field.name === 'title') {
            fields.push(<div className='font-normal' key={index}>{entry[field.name] ?? '-'}</div>)
          } else {
            fields.push(<div key={index}>{entry[field.name] ?? '-'}</div>)
          }
        }
        if (isPropertyType(field, 'integer') ?? isPropertyType(field, 'number')) {
          if (field.name === 'duration') {
            fields.push(<Duration value={entry[field.name]} />)
          } else {
            fields.push(<div key={index}>{entry[field.name]}</div>)
          }
        }
        if (isPropertyType(field, 'boolean')) {
          fields.push(
            <div key={index}>
              {entry[field.name]
                ? (
                  <div className='inline-block w-6 h-6 bg-green-100 rounded p-1.5'>
                    <CheckIcon className='w-3 h-3 text-green-700' />
                  </div>
                )
                : (
                  <div className='inline-block w-6 h-6 bg-gray-100 rounded p-1.5'>
                    <XMarkIcon className='w-3 h-3 text-gray-700' />
                  </div>
                )
              }
            </div>
          )
        }
        if (isPropertyType(field, 'array')) {
          if (field.name === 'roles') {
            fields.push(<div className='space-x-2' key={index}>
              {entry[field.name].map(entry => {
                return (
                  <div className='inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600' key={entry}>
                    {translateRoles(entry)}
                  </div>
                )
              })}
            </div>)
          } else if (field.name === 'data') {
            return fields.push(
              <pre>
                <div>{JSON.stringify(JSON.parse(entry[field.name]), null, 2)}</div>
              </pre>
            )
          } else if ('fr' in entry[field.name]) {
            fields.push(<div key={index}>{entry[field.name].fr}</div>)
          } else {
            fields.push(<div key={index}>{(entry[field.name] as never[]).toString()}</div>)
          }
        }
      }

      if ('anyOf' in field.properties || '$ref' in field.properties) {
        if (entry[field.name]) {
          if (entry[field.name]['@type'] === 'Image') {
            fields.push(
              <div key={index}>
                <img alt='' className='w-12 h-12 object-cover rounded-full' src={(entry[field.name] as Image).url} />
              </div>
            )
          } else if (entry[field.name]['@type'] === 'User') {
            const user: User = entry[field.name]
            fields.push(
              <div className='flex flex-nowrap items-center gap-2' key={index}>
                <div className='inline-block w-6 h-6 bg-gray-100 rounded p-1.5'>
                  <UserIcon className='w-3 h-3 text-gray-700' />
                </div>

                <div className='whitespace-nowrap'>{user.email}</div>
              </div>
            )
          } else if (entry[field.name]['@type'] === 'Country') {
            const country: Country = entry[field.name]
            fields.push(<div key={index}>{`${country.description.fr}`}</div>)
          } else if (entry[field.name].name && 'fr' in entry[field.name].name) {
            fields.push(<div key={index}>{entry[field.name].name.fr}</div>)
          } else {
            fields.push(<div key={index}>{entry[field.name].name ?? entry[field.name]['@id']}</div>)
          }
        } else {
          fields.push(<div key={index}>-</div>)
        }
      }
    }

    if (field.name === 'actions') {
      fields.push(
        <ApiResourceListEntryActions
          definition={definition}
          entry={entry}
          identifierAttribute={identifierAttribute}
          onReload={onReload}
          withCloneOption={withCloneOption}
          withCopyOption={withCopyOption}
          withDeleteOption={withDeleteOption}
          withEditOption={withEditOption}
        />
      )
    }
  })

  return (
    <tr
      className='bg-white hover:bg-gray-100 cursor-pointer group'
      onClick={onClickHandler}
    >
      {fields.map((field, index) => (
        <td
          className='p-3 text-base'
          key={index}
        >
          {field}
        </td>
      ))}
    </tr>
  )
}

export default ApiResourceListEntry
