import { ShoppingCartIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  type BookingRequestStatusBadgeProps
} from '@pages/booking-requests/entry/booking-request-status-badge/booking-request-status-badge.interfaces'

const BookingRequestStatusBadge = ({ icon = true, status, type }: BookingRequestStatusBadgeProps) => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'booking-requests' })

  const isGreenStatus = ['accepted']
  const isRedStatus = ['cancelled']
  const isYellowStatus = ['requested']
  const isBlueStatus = ['proposed']
  const isGrayStatus = ['']
  const isOrangeStatus = ['']

  const getDefaultIcon = (type) => {
    switch (type) {
      case 'state':
        return ShoppingCartIcon
      default:
        return null
    }
  }

  const DefaultIcon = getDefaultIcon(type)

  return (
    <div
      className={classNames(
        'inline-flex items-center justify-center space-x-1 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset',
        {
          'text-gray-600 bg-gray-50 ring-gray-500/10 ring-1 ring-inset': isGrayStatus.includes(status),
          'text-green-700 bg-green-50 ring-green-600/20 ring-1 ring-inset': isGreenStatus.includes(status),
          'text-orange-700 bg-orange-50 ring-gray-500/10 ring-1': isOrangeStatus.includes(status),
          'text-red-700 bg-red-50 ring-red-600/10 ring-1 ring-inset': isRedStatus.includes(status),
          'text-white bg-blue-400 ring-gray-500/10 ring-1 ring-inset': isBlueStatus.includes(status),
          'text-yellow-700 bg-yellow-50 ring-gray-500/10 ring-1': isYellowStatus.includes(status)
        }
      )}
    >
      {icon && DefaultIcon && <DefaultIcon className='flex-none size-4' />}

      <span>{translateResource(`states.${type}.${status}`)}</span>
    </div>
  )
}

export default BookingRequestStatusBadge
